// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/MyDataComponent.vue';
import Data from './components/DataComponent.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/data',
    name: 'Data',
    component: Data
  },
  // 在访问没有的路由时重定向到首页
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
