<template>
  <div class="container">
    <div class="avatar">
      <img :src="avatar_url" alt="Avatar">
      <h1>{{ username }}</h1>
    </div>
    <div class="data-stream" ref="dataContainer">
      <div class="data-item" v-for="(item, index) in dataStream" :key="index">
        <strong>Status:</strong> {{ item.status }}<br>
        <strong>Message:</strong> {{ item.message }}<br>
        <strong v-if="item.timestamp">Timestamp:</strong> {{ item.timestamp }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';
const dataStream = ref([]);
const dataContainer = ref(null);
const avatar_url = ref('');
const username = ref('666');

const scrollToBottom = () => {
  const container = dataContainer.value;
  container.scrollTop = container.scrollHeight;
};

// 在组件挂载后，创建一个 EventSource 实例，监听服务器端的事件
onMounted(() => {
  // 获取url链接
  // const url = new URL(window.location.href);

  // 从 URL 中获取 token
  const urlParams = new URLSearchParams(window.location.search);
  
  username.value = urlParams.get('username');
  avatar_url.value = urlParams.get('avatar_url');
  console.log(process.env.VUE_APP_API_URL);
  
  const eventSource = new EventSource(`${process.env.VUE_APP_API_URL}/rw?token=${urlParams.get('token')}`);
  
  // 处理服务器端发送的消息
  eventSource.onmessage = async (event) => {  
    const data = JSON.parse(event.data);
    dataStream.value.push(data);
    await nextTick(); // nextTick 用于确保数据更新后再滚动到底部
    scrollToBottom();
  };

  eventSource.onerror = (error) => {
    console.error('EventSource failed:', error);
    eventSource.close();
  };
});
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.avatar img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.data-stream {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 750px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.data-item {
  margin: 10px 0;
  background: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .data-stream {
    max-width: 100%;
  }
}
</style>
