<template>
  <div class="box">
    <div class="container">
      <h1>签到系统</h1>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <div id="account-login">
        <form @submit.prevent="login">
          <div class="form-group">
            <label for="account">账号:</label>
            <input type="text" v-model="account" required>
          </div>
          <div class="form-group">
            <label for="password">密码:</label>
            <input type="password" v-model="password" required>
          </div>
          <button type="submit">登录</button>
        </form>
      </div>
      <div id="token-login" class="hidden">
        <form @submit.prevent="signin">
          <!-- <div class="form-group">
            <label for="cookie">Cookie:</label>
            <input type="text" v-model="cookie" required>
          </div> -->
          <div class="form-group">
            <label for="token">Token:</label>
            <input type="text" v-model="token" required>
          </div>
          <!-- <div class="form-group">
            <label for="scid">SCID:</label>
            <input type="text" v-model="scid" required>
          </div> -->
          <!-- 添加一个是否保存token的选项 -->

          <button type="submit">确认</button>
        </form>
      </div>
      <hr />
      <button id="toggle-button" @click="toggleForms">切换登录方式</button>
      <div class="form-group-div">
        <label class="form-group-label" for="save-token">保存token:</label>
        <input class="form-group-label" type="checkbox" id="save-token" name="save-token" value="save-token">
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from '../axios';
import { useRouter } from 'vue-router';

const router = useRouter();
const account = ref('');
const password = ref('');
// const cookie = ref('acw_tc=b6f23f9c17198195302474234e60870ecec1bb6c17745e8d27bab0ef00; cdn_sec_tc=b6f23f9c17198195302474234e60870ecec1bb6c17745e8d27bab0ef00; acw_tc=2f624a4217198195302692229e07d3b207a134ff095921e287e5a8edc14be6');
const token = ref('');
// const scid = ref('{"$identity_anonymous_id":"5438430e5c69b121","$identity_android_id":"5438430e5c69b121","identity_inid":"1718026469062_aa8c2e3bde8347a0a7ca016131d6dd45","identity_domain":"hfsejkbhjk"}');
const errorMessage = ref('');

const showError = (message) => {
  errorMessage.value = message;
  setTimeout(() => {
    errorMessage.value = '';
  }, 5000);
};

// 判断本地是否保存了token
if (localStorage.getItem('token')) {
  token.value = localStorage.getItem('token');
}

const login = async () => {
  try {
    const response = await axios.post('/login', {
      account: account.value,
      password: password.value
    });
    
    if (response.data?.code === 2000) {
      // 延迟2秒跳转，以便用户看到登录成功的提示
      setTimeout(() => {
        router.push(`/data?token=${response.data?.data?.user_token}&avatar_url=${response.data.data?.avatar_url}&username=${response.data?.data?.username}`); // 签到成功后跳转到 data 页面
      }, 2000);
    }else{
      showError(response.data?.msg || '登录失败，请检查您的账号和密码');
    }

  } catch (error) {
    showError(error.response?.data?.msg || '登录失败，请检查您的账号和密码');
    console.error(error);
  }
};

const signin = async () => {
  try {
    // 先验证token是否有效
    // const response = await axios.post('/signin', {
    //   token: token.value,
    //   // cookie: cookie.value,
    //   // scid: scid.value
    // });

    // 判断是否保存token
    if (document.getElementById('save-token').checked) {
      localStorage.setItem('token', token.value);
    }

    setTimeout(() => {
      router.push(`/data?token=${token.value}`);
    }, 2000);
  } catch (error) {
    console.error(error);
  }
};

const toggleForms = () => {
  document.getElementById('account-login').classList.toggle('hidden');
  document.getElementById('token-login').classList.toggle('hidden');
  // 显示form-group-div
  document.getElementsByClassName('form-group-div')[0].style.display = 'block';
  // 切换为账号登陆时隐藏保存token选项
  if (!document.getElementById('account-login').classList.contains('hidden')) {
    document.getElementsByClassName('form-group-div')[0].style.display = 'none';
    // 清除保存token选项的勾选
    document.getElementById('save-token').checked = false;
  }
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  text-align: center;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

#toggle-button {
  background-color: #28a745;
}

#toggle-button:hover {
  background-color: #218838;
}

.hidden {
  display: none;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.form-group-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* 默认不显示 */
  display: none;

}

.form-group-label {
  width: 90px;
  /* float: left; */
}

#save-token {
  width: 30px;
  /* float: left; */
}
</style>
